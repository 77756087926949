var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sof-box-entities main-text bold display-table"},[_c('div',{staticClass:"table-row"},[_c('div',{staticClass:"table-cell"},[_c('GlScoreDot',{staticClass:"mr-2",attrs:{"score":100,"width":"6px"}})],1),_vm._m(0),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"table-row"},[_c('div',{staticClass:"table-cell"},[_c('GlScoreDot',{staticClass:"mr-2",attrs:{"score":50,"width":"6px"}})],1),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"table-row"},[_c('div',{staticClass:"table-cell"},[_c('GlScoreDot',{staticClass:"mr-2",attrs:{"score":1,"width":"6px"}})],1),_vm._m(6),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"mr-2 fs-10"},[_vm._v("Hydra Marketplace")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"mr-1"},[_vm._v("100 BTC")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell text-end-last"},[_c('span',{staticClass:"ml-1"},[_vm._v(" 86%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"mr-2 fs-10"},[_vm._v("Cryptonator.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"mr-1"},[_vm._v("10 BTC")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell text-end-last"},[_c('span',{staticClass:"ml-1"},[_vm._v(" 10%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"mr-2 fs-10"},[_vm._v("Binance.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"mr-1"},[_vm._v(" 1 BTC")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell text-end-last"},[_c('span',{staticClass:"ml-1"},[_vm._v(" 1%")])])
}]

export { render, staticRenderFns }